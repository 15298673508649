
require.register("isarray/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "isarray");
  (function() {
    var toString = {}.toString;

module.exports = Array.isArray || function (arr) {
  return toString.call(arr) == '[object Array]';
};
  })();
});