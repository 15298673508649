
require.register("axios/lib/cancel/isCancel.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "axios");
  (function() {
    'use strict';

module.exports = function isCancel(value) {
  return !!(value && value.__CANCEL__);
};
  })();
});