
require.register("vue/dist/vue.runtime.common.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "vue");
  (function() {
    if ('development' === 'production') {
  module.exports = require('./vue.runtime.common.prod.js')
} else {
  module.exports = require('./vue.runtime.common.dev.js')
}
  })();
});